import Application from 'components/comachicart/Application';
import Layout from 'components/comachicart/Layout';
import News from 'components/comachicart/News';
import SEO from 'components/seo';
import React from 'react';

const NewsPage = () => {
  return (
    <Layout>
      <SEO title='comachicart' path='comachicart/news' />
      <News />
      <Application />
    </Layout>
  );
};

export default NewsPage;
